import { RootState, ReceptionState } from "@/store/type";
import { ActionContext } from "vuex";
import ApiPlugin, { Activity, Guest } from "@/api";
import { getToken, getUserId } from "@/common/storage";
import { showAuth } from "@/api/auth";
import api from "@/api";
import router from "@/router";

export interface DistanceConfig {
  top: number | string;
  right: number | string;
  bottom: number | string;
  left: number | string;
}

export interface Page {
  backgroundColor: string;
}

export type Lang = "zh" | "en";

export default {
  state: {
    info: null, // false 表示当前会议不存在
    page: {},
    controls: null,
    guest: null,
    lang:
      localStorage.getItem("lang") || navigator.language?.substr(0, 2) || "zh",
  },
  getters: {
    info: (state: ReceptionState) => state.info,
    page: (state: ReceptionState) => state.page,
    controls: (state: ReceptionState) => state.controls,
    guest: (state: ReceptionState) => state.guest,
    lang: (state: ReceptionState) => state.lang,
  },
  mutations: {
    updateInfo(state: ReceptionState, info: Activity) {
      state.info = info;
    },
    setPage(state: ReceptionState, page: Page) {
      state.page = page;
    },
    setControls(state: ReceptionState, controls: unknown) {
      state.controls = controls;
    },
    changeLang(state: ReceptionState, lang: Lang) {
      localStorage.setItem("lang", lang);
      state.lang = lang;
    },
    updateUserGuest(state: ReceptionState, guest: Guest | null) {
      state.guest = guest;
    },
  },
  actions: {
    getActivity(context: ActionContext<ReceptionState, RootState>, id: number) {
      ApiPlugin.getActivity(id).then((res) => {
        if (res) {
          try {
            const config = JSON.parse(res.config);
            context.commit("setPage", config.page || null);
            context.commit("setControls", config.list || null);
          } catch (e) {
            console.error("活动配置解析失败：", e);
          }
          context.commit("updateInfo", res);
        } else {
          context.commit("updateInfo", false);
        }
      });
    },
    getUserGuest(
      context: ActionContext<ReceptionState, RootState>,
      force?: boolean
    ): Promise<Guest | null> {
      const route = router.currentRoute;
      if (!getToken()) {
        showAuth(true);
        return Promise.reject();
      } else {
        if (context.getters.guest && !force) {
          return Promise.resolve(context.getters.guest);
        } else {
          const id = getUserId();
          const query = `&filter[wx_user_id]=${id}&filter[activity_id]=${route.value.params.id}`;
          return api.getQueryGuest(query).then((res) => {
            context.commit("updateUserGuest", res);
            return res;
          });
        }
      }
    },
  },
};
